import { Fragment } from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import "./Home.scss";
import carousel1 from "../../assets/images/homeCarousel-img.webp";
import titleImg from "../../assets/images/delicious.png";
import Image from "../../common/components/Image/Image";
import Footer from "../../common/components/Footer/Footer";
import Header from "../../common/components/Header/Header";
import PremiumMeals from "../../common/components/PremiumMeals/PremiumMeals";
import DietMeals from '../../common/components/DietMeals/DietMeals';
import TastyMeals from '../../common/components/TastyMeals/TastyMeals';
import FullWidthTitle from '../../common/components/FullWidthTitle/FullWidthTitle';
import dietImg from "../../assets/images/diet-img.png";

function Home() {

  const dietMealValue = {
    icon: titleImg,
    title: 'Diet Meals',
    description: `We take care for your health and even make you health conscious. We can guide you to make a healthy diet, help you to loss weight, make you healthy,
    helps you to fight with a decease and many more. See our more detailed diet planning options from us, get a professional result oriented diet plans. We love to see you a winner!`,
    description_2: '',
    link_1_text: 'PLAN YOUR DIET',
    link_1: '/plan-diet',
    link_2_text: 'LEARN ABOUT FOOD AND HEALTH',
    link_2: '/',
    image: dietImg
  }

  const titleValue = {
    description: 'We have these options for your primary need - Food',
    link_1_text: '',
  }

  return (
    <Fragment>
      <Header />

      <div className="mt-5 home-wrapper">
        <div className='carousel-container'>
          <Carousel controls={false}>
            <Carousel.Item>
              <Row>
                <Col md={6}>
                  <div className="carousel-content">
                    <h1>eaty<span>healthy</span></h1>
                    <h3>Makes Easy your Food needs</h3>
                    <p>At EatyHealthy, we’re redefining the way you eat – with convenience,
                      taste, and health in mind. Say goodbye to meal planning, grocery

                      shopping, and cooking hassles. Say hello to EatyHealthy, your ticket
                      to delicious, chef-curated meals delivered right to your doorstep.</p>
                  </div>
                </Col>
                <Image colMdSize={6} imgClass="" class="carousel-img" alt="" image={carousel1} />
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <Col md={6}>
                  <div className="carousel-content">
                    <h1>eaty<span>healthy</span></h1>
                    <h3>Makes Easy your Food needs</h3>
                    <p>At EatyHealthy, we’re redefining the way you eat – with convenience,
                      taste, and health in mind. Say goodbye to meal planning, grocery
                      shopping, and cooking hassles. Say hello to EatyHealthy, your ticket
                      to delicious, chef-curated meals delivered right to your doorstep.</p>
                  </div>
                </Col>
                <Image colMdSize={6} class="carousel-img" alt="" image={carousel1} />
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <Col md={6}>
                  <div className="carousel-content">
                    <h1>eaty<span>healthy</span></h1>
                    <h3>Makes Easy your Food needs</h3>
                    <p>At EatyHealthy, we’re redefining the way you eat – with convenience,
                      taste, and health in mind. Say goodbye to meal planning, grocery
                      shopping, and cooking hassles. Say hello to EatyHealthy, your ticket
                      to delicious, chef-curated meals delivered right to your doorstep.</p>
                  </div>
                </Col>
                <Image colMdSize={6} class="carousel-img" alt="" image={carousel1} />
              </Row>
            </Carousel.Item>
          </Carousel>
        </div>

        <FullWidthTitle titleOption={titleValue} />

        {/* <div className='title-primary'>
          <h3>We have these options for your primary need - Food</h3>
        </div> */}

        <DietMeals dietOption={dietMealValue} />

        <hr />

        <TastyMeals />

        <hr />

        <div className='mealSubscription-wrapper'>
          <h3>Introducing NourishMeals - Your Delicious Meal Subscription</h3>
          <div className='mealSubscription-img'>
            <div className='nonVeg-wrapper'>
              <h6>Chef's Special Non-veg Premium</h6>
              <p>Our team of experienced chefs handcrafts each meal with the finest ingredients
                to ensure taste, quality, and nutrition in every bite.</p>
              <p>Plans Starts from</p>
              <h5>₹250 per Day</h5>
              <button className='btn-green'>REVIEW & BOOK</button>
            </div>
          </div>
        </div>

        <div className='chefCrafted-wrapper'>
          <h3>Chef-Crafted Delights</h3>
          <div className='chefCrafted-img'>
            <div className='chefCrafted-content'>
              <h5>Super Daily Veg</h5>
              <p>Our team of experienced chefs <br /> handcrafts each meal with the finest </p>
              <h6>Plans Starts from</h6>
              <h5 className='price'>₹200 per Day</h5>
              <button className='btn-white'>REVIEW & BOOK</button>
            </div>
          </div>
        </div>

        <PremiumMeals />

        <PremiumMeals />

        <div className='finalCover-wrapper'>
          <div className='finalCover-img'>
            <div className='nonVeg-wrapper'>
              <h6>Chef's Special Non-veg Premium</h6>
              <p>Our team of experienced chefs handcrafts each meal with the finest ingredients
                to ensure taste, quality, and nutrition in every bite.</p>
              <p>Plans Starts from</p>
              <h5>₹200 per Day</h5>
              <button className='btn-green'>REVIEW & BOOK</button>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </Fragment>

  );
}


export default Home;
