import './PremiumMeals.scss';
import { Container, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "../Image/Image";
import premiumImg from "../../../assets/images/card-img.png";

const PremiumMeals = () => {
    return (
        <div className='premiumMeals-wrapper'>
            <Container>

                <div className='premiumMeals-dflex'>
                    <div>
                        <h3>Chef-Crafted Delights</h3>
                        <h6>Tasty Full Time Meals - Premium</h6>
                    </div>
                    <a href="#">View All Plans</a>
                </div>
                <hr />
                <Row>
                    <Col md={3} sm={8} className='dailyVeg'>
                        <Image noCol="true" image={premiumImg} />
                        <div className='planStarts'>
                            <h6>Plans Starts from</h6>
                            <span>₹250/Day</span>
                        </div>
                        <h5>Super Daily Veg</h5>
                        <p>Our team of experienced chefs handcrafts each meal with the finest ingredients to
                            ensure taste, quality, and nutrition in every bite.</p>
                        <hr />
                    </Col>
                    <Col md={3} sm={8} className='dailyVeg'>
                        <Image noCol="true" image={premiumImg} />
                        <div className='planStarts'>
                            <h6>Plans Starts from</h6>
                            <span>₹250/Day</span>
                        </div>
                        <h5>Super Daily Veg</h5>
                        <p>Our team of experienced chefs handcrafts each meal with the finest ingredients to
                            ensure taste, quality, and nutrition in every bite.</p>
                        <hr />
                    </Col>
                    <Col md={3} sm={8} className='dailyVeg'>
                        <Image noCol="true" image={premiumImg} />

                        <div className='planStarts'>
                            <h6>Plans Starts from</h6>
                            <span>₹250/Day</span>
                        </div>
                        <h5>Super Daily Veg</h5>
                        <p>Our team of experienced chefs handcrafts each meal with the finest ingredients to
                            ensure taste, quality, and nutrition in every bite.</p>
                        <hr />
                    </Col>
                    <Col md={3} sm={8} className='dailyVeg'>
                        <Image image={premiumImg} />
                        <div className='planStarts'>
                            <h6>Plans Starts from</h6>
                            <span>₹250/Day</span>
                        </div>
                        <h5>Super Daily Veg</h5>
                        <p>Our team of experienced chefs handcrafts each meal with the finest ingredients to
                            ensure taste, quality, and nutrition in every bite.</p>
                        <hr />
                    </Col>
                </Row>

                <Row>
                    <Col md={3} sm={8} className='dailyVeg'>
                        <Image image={premiumImg} />
                        <div className='planStarts'>
                            <h6>Plans Starts from</h6>
                            <span>₹250/Day</span>
                        </div>
                        <h5>Super Daily Veg</h5>
                        <p>Our team of experienced chefs handcrafts each meal with the finest ingredients to
                            ensure taste, quality, and nutrition in every bite.</p>
                        <hr />
                    </Col>
                    <Col md={3} sm={8} className='dailyVeg'>
                        <Image image={premiumImg} />
                        <div className='planStarts'>
                            <h6>Plans Starts from</h6>
                            <span>₹250/Day</span>
                        </div>
                        <h5>Super Daily Veg</h5>
                        <p>Our team of experienced chefs handcrafts each meal with the finest ingredients to
                            ensure taste, quality, and nutrition in every bite.</p>
                        <hr />
                    </Col>
                    <Col md={3} sm={8} className='dailyVeg'>
                        <Image image={premiumImg} />
                        <div className='planStarts'>
                            <h6>Plans Starts from</h6>
                            <span>₹250/Day</span>
                        </div>
                        <h5>Super Daily Veg</h5>
                        <p>Our team of experienced chefs handcrafts each meal with the finest ingredients to
                            ensure taste, quality, and nutrition in every bite.</p>
                        <hr />
                    </Col>
                    <Col md={3} sm={8} className='dailyVeg'>
                        <Image image={premiumImg} />
                        <div className='planStarts'>
                            <h6>Plans Starts from</h6>
                            <span>₹250/Day</span>
                        </div>
                        <h5>Super Daily Veg</h5>
                        <p>Our team of experienced chefs handcrafts each meal with the finest ingredients to
                            ensure taste, quality, and nutrition in every bite.</p>
                        <hr />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PremiumMeals;