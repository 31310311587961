import { Col } from 'react-bootstrap';
import './FullWidthTitle.scss';
import { Link } from 'react-router-dom';

const FullWidthTitle = (props: any) => {
    const { titleOption } = props;
    return (
        <div className='title-primary'>
            <Col md={8} className='titleContent'>
                <h3>{titleOption.description}</h3>
                {titleOption.link_1_text !== '' ?
                    <div className='titleBtn'>
                        <Link to={titleOption.link}><button className='btn-red'>{titleOption.link_1_text}</button></Link>
                    </div>
                    : ''}
            </Col>
        </div>
    );
}

export default FullWidthTitle;