import './RangeSlider.scss';

const RangeSlider = (props: any) => {
    
    return(

        props.tracker === true ?
            <div className="diet">
                <input className='active' min={1} max={50} value={props.value} type="range" disabled />
            </div>
        :
        <div className="diet">
            <input className='inactive' type="range" value={props.value} readOnly />
        </div>
    )
}

export default RangeSlider;