import './DietPage.scss';
import { Container, Row } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import React, { Fragment } from 'react';
import Image from "../../common/components/Image/Image";
import Footer from "../../common/components/Footer/Footer";
import Header from "../../common/components/Header/Header";
import DietMeals from '../../common/components/DietMeals/DietMeals';
import Breadcrumbs from '../../common/components/Breadcrumbs/Breadcrumbs';
import FullWidthTitle from '../../common/components/FullWidthTitle/FullWidthTitle';
import BannerBlock from '../../common/components/BannerBlock/BannerBlock';
import PremiumMeals from '../../common/components/PremiumMeals/PremiumMeals';
import titleImg from "../../assets/images/delicious.png";
import dietImg from "../../assets/images/diet-img.png";


const DietPage = () => {

    const dietMealValue = {
        icon: titleImg,
        title: 'Diet Meals',
        description: `Let’s know your aim for Diet. We have specialised plans for your body, we will help you to design your diet which requires for your body condition.`,
        description_2: 'You are not alone, we are with you to win this goal.',
        link_1_text: 'PLAN YOUR DIET',
        link_1: '/plan-diet',
        link_2_text: 'LEARN ABOUT FOOD AND HEALTH',
        link_2: '/',
        image: dietImg
    }

    const titleValue = {
        description: 'See some options for you, then let us decide.',
        link_1_text: '',
        link: ''
    }

    const titleValue2 = {
        description: 'Other than these, we have more specialised diets for you. Check out all on Plan your Diet tool.',
        link_1_text: 'PLAN YOUR DIET',
        link: '/plan-diet'
    }

    return (
        <Fragment>

            <Header />

            <Breadcrumbs />

            <div className='dietPage-wrapper'>
                <DietMeals dietOption={dietMealValue} />

                <FullWidthTitle titleOption={titleValue} />

                <BannerBlock imgPosition='right' />

                <hr />

                <BannerBlock imgPosition='left' />

                <hr />

                <BannerBlock imgPosition='right' />

                <div className='titleValue-wrapper'>
                    <FullWidthTitle titleOption={titleValue2} />
                </div>

                <div className='presetPlan-wrapper'>
                    <Col md={6}>
                        <h2>Some Preset Plans</h2>
                        <p>See some of our preset plans, you can go through and see how it works.
                            We strongly suggest you to <span> Plan your diet</span>, so our AI tools will
                            suggest your the best diet plans for you. Also you will get
                            personalised nutrition support (Optional).</p>
                    </Col>
                </div>

                <PremiumMeals />

                <PremiumMeals />
            </div>


            <Footer />
        </Fragment>
    );
}

export default DietPage;