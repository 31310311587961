import { Route, Routes } from 'react-router-dom';
import PlanADiet from '../../views/PlanADiet/PlanADiet';
import Home from '../../views/Home/Home';
import DietPage from '../../views/DietPage/DietPage';
import MealsPage from '../../views/MealsPage/MealsPage';

const AppRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/diet-plans" element={<DietPage />} />
        <Route path="/meal-plans" element={<MealsPage />} />
        <Route path="/plan-diet" element={<PlanADiet />} />
    </Routes>
  );
}

export default AppRoutes;
