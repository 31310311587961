import './App.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { BrowserRouter as Route } from 'react-router-dom';
import AppRoutes from './common/routes';
import { setAccessToken, setIsAuthenticated } from './redux/pages/loginSlice';
import ScrollToTop from './common/components/ScrollToTop';
import { ToastContainer } from 'react-toastify';

function App() {

  //redux
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if(sessionStorage.getItem('token')) {
      dispatch(setIsAuthenticated(true));
      dispatch(setAccessToken(sessionStorage.getItem('token')));
    }
  }, []);

  return (
    <Route>
      <ScrollToTop />
      <AppRoutes />
      <ToastContainer />
    </Route>
  );
}

export default App;
