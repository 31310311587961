import './MealsPage.scss';
import { Container, Row } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import React, { Fragment } from 'react';
import Image from "../../common/components/Image/Image";
import Footer from "../../common/components/Footer/Footer";
import Header from "../../common/components/Header/Header";
import TastyMeals from '../../common/components/TastyMeals/TastyMeals';
import Breadcrumbs from '../../common/components/Breadcrumbs/Breadcrumbs';
import DietMeals from '../../common/components/DietMeals/DietMeals';
import FullWidthTitle from '../../common/components/FullWidthTitle/FullWidthTitle';
import BannerBlock from '../../common/components/BannerBlock/BannerBlock';
import PremiumMeals from '../../common/components/PremiumMeals/PremiumMeals';
import titleImg from "../../assets/images/delicious.png";
import tasteImg from "../../assets/images/diet-img.png";

const MealsPage = () => {

    const tasteMealValue = {
        
        icon: titleImg,
        title: 'Tasty Meals',
        description: `Don’t have time for preparing food or even Effectively utilise your time and invest your time in your potential skills, we will take care of your fooding by preparing healthy food in our hygienic kitchen.`,
        description_2: 'Choose from our multiple meal plans.',
        link_1_text: 'PLAN YOUR MEAL',
        link_1: '/plan-meal',
        link_2_text: 'LEARN ABOUT TASTY FOODS',
        link_2: '/',
        image: tasteImg
    }

    const titleValue1 = {
        description: 'See some options for you, then let us decide.',
        link_1_text: '',
    }

    const titleValue2 = {
        description: 'Other than these, we have more specialised Meals for you. Check out all on Plan your Meal tool.',
        link_1_text: 'PLAN YOUR MEALS',
        link: ''
    }

    return (
        <Fragment>
            <Header />
            <Breadcrumbs />
            <div className='tastyMealsPage-wrapper'>
                <DietMeals dietOption={tasteMealValue} />

                <FullWidthTitle titleOption={titleValue1} />

                <BannerBlock imgPosition='right' />

                <hr />

                <BannerBlock imgPosition='left' />

                <hr />

                <BannerBlock imgPosition='right' />

                <FullWidthTitle titleOption={titleValue2} />

                <div className='presetPlan-wrapper'>
                    <Col md={6}>
                        <h2>Some Preset Plans</h2>
                        <p>See some of our preset plans, you can go through and see how it works.
                            We strongly suggest you to <span> Plan your diet</span>, so our AI tools will
                            suggest your the best diet plans for you. Also you will get
                            personalised nutrition support (Optional).</p>
                    </Col>
                </div>

                <PremiumMeals />

                <PremiumMeals />


            </div>

            <Footer />
        </Fragment>
    );
}

export default MealsPage;