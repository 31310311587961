import './DietPlanOption.scss';


const DietPlanOption = (props: any) => {
    return (
        <div className="dietPlanOption-wrapper">
        <div className="dietPlanOption">
            <h5>{props.option.title}</h5>
            <h4>{props.option.calories}<span>calories</span></h4>
            <p>{props.option.description}</p>
            <h4>{props.option.weight}<sub className="suffix">kg</sub></h4>
            <button className="btn btn-primary">Proceed</button>
        </div>
    </div>
    )
}

export default DietPlanOption;