import './DietMeals.scss';
import { Container, Row } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import Image from "../Image/Image";
import { Link } from 'react-router-dom';

const DietMeals = (props: any) => {
    
    const { dietOption } = props;
    
    return (
        <div className='dietMeals-wrapper'>
            <Container>
                <Row>
                    <Col md={6}>
                        <h2>  <Image alt={dietOption.title} image={dietOption.icon} /> {dietOption.title}</h2>
                        <p>
                            {dietOption.description}
                            {dietOption.description_2 !== '' ? <><br /><span className='description_two'>{dietOption.description_2}</span></> : ''}
                        </p>
                        <div className='dietMeals-btngroup'>
                            <Link to={dietOption.link_1}><button className='btn-red'>{dietOption.link_1_text}</button></Link>
                            <Link to={dietOption.link_2}><button className='btn-green'>{dietOption.link_2_text}</button></Link>
                        </div>
                    </Col>
                    <Image imgClass="img-fluid" colMdSize={6} class="dietMeals-img" alt={dietOption.title} image={dietOption.image} />
                </Row>
            </Container>
        </div>
    );
}

export default DietMeals;