import './TastyMeals.scss';
import { Container, Row } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import Image from "../Image/Image";
import titleImg from "../../../assets/images/delicious.png";
import tastyImg from "../../../assets/images/tastymeals.png";


const TastyMeals = () => {
    return (
        <div className='tastyMeals-wrapper'>
        <Container >
          <Row>
            <Image imgClass="img-fluid" colMdSize={6} class="" alt="" image={tastyImg} />

            <Col md={6}>
              <h2><Image alt="" image={titleImg} />Tasty Meals</h2>
              <p>Go through our most popular daily subscription Meals if you weights
                “Taste” more than healthy It’s hygienically & naturally prepared,
                still healthier than your restorent foods and possibly tastier
                than home made daily meals.</p>
              <button className='btn-green'>PLAN YOUR MEAL</button>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export default TastyMeals;