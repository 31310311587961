import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";

export interface PlanADietState {
    errors: any;
    isLoading: boolean;
    dietOptionsList: any;
    lifeStyleList: any;
}

const initialState: PlanADietState = {
    errors: null,
    isLoading: false,
    dietOptionsList: [],
    lifeStyleList: [],
}

const dietSlice = createSlice({
    name: 'planaDiet',
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setDietOptionsList: (state, { payload }: PayloadAction<any>) => {
            state.dietOptionsList = payload;
        },
        setLifeStyleList: (state, { payload }: PayloadAction<any>) => {
            state.lifeStyleList = payload;
        }
    }
});

export const getLifStyleList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try{
        let URL = '/lifestyle/list';
        const response = await axiosInstance({ data: { url: URL, method: 'get'}, token: false});
        if(response.data) {
            dispatch(setLoading(false));
            if(response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setLifeStyleList(response.data.list_activity));
            }
        }
    }
    catch (error: any) {
        dispatch(setLoading(false));
    }
}

export const getDietOptionsList = (body: any, setDietOptionError: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try{
        let URL = '/plandiet/index';
        const response = await axiosInstance({ data: { url: URL, method: 'get', data: body }, token: true});
        if(response.data) {
            dispatch(setLoading(false));
            if(response.data.status && response.data.status === 'success') {
                dispatch(setLoading(false));
                dispatch(setDietOptionsList(response.data));
            }
        }
    }
    catch (error: any) {
        dispatch(setLoading(false));
        setDietOptionError(error?.response.data?.message);
    }
}

export const {
    setErrors,
    setLoading,
    setDietOptionsList,
    setLifeStyleList
} = dietSlice.actions;

export default dietSlice.reducer;
