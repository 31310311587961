import Col from "react-bootstrap/Col";

const Image = (props: any) => {

    return (
        props.noCol === 'true' ?
            <img src={props.image} alt={props.alt} className={props.imgClass} />
        :
            props.colMdSize || props.colSmSize || props.colLgSize ?
                <Col className={props.class} sm={props.colSmSize} md={props.colMdSize} lg={props.colLgSize}>
                    <img src={props.image} alt={props.alt} className={props.imgClass} />
                </Col>
            :
                <Col className={props.class}>
                    <img src={props.image} className={props.imgClass} alt={props.alt} />
                </Col>
    )
}

export default Image;