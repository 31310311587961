import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.scss';

const Footer = () => {
    return (
        <div className='footer-wrapper'>
            <hr />
            <Container className='footer-content'>
                <Row>
                    <Col className='servicedBy-wrapper' md={5}>
                        <h5>Serviced By</h5>
                        <p>Eatyhealthy Kitchens,<br />India</p>
                    </Col>
                    <Col className='customerService-wrapper' md={3}>
                        <h5>Customer Service</h5>
                        <a href="#">sales@eatyhealthy.com</a>
                        <p>Phone <a href="#">+91 7907508097</a></p>
                        <a href="#">Submit a feedback</a>
                    </Col>
                    <Col className='siteMap-wrapper' md={4}>
                        <h5>Site Map</h5>
                        <ul>
                            <li>Home</li>
                            <li>Meal Plans</li>
                            <li>Diet Plans</li>
                            <li>How it works?</li>
                            <li>Delivery Options</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <hr />
            <p className='copyright'>&copy; 2023 eatyhealthy.com</p>
        </div>
    );
}

export default Footer;