import { Col, Container, Row } from 'react-bootstrap';
import './BannerBlock.scss';
import Image from "../Image/Image";
import bannerBlockImg from "../../../assets/images/planAdiet-img.png";

const BannerBlock = (props: any) => {
    return (
        <div className='bannerBlock-wrapper'>
            {props.imgPosition == 'right' ?
                <Container >
                    <Row>
                        <Col md={6}>
                            <h6>Low Calorie Diet for</h6>
                            <h2>Weight Loss</h2>
                            <p>Do you have double to loss weight? <br /> It may be because of wrong diet.
                                We have suitable pre-designed <span>Diet plans</span> for you to achieve your aim.
                                Also you can consult with our professional <span>Nutritionist</span>.</p>
                            <div className='bannerBlock-btngroup'>
                                <button className='btn-red'>PLAN YOUR DIET</button>
                                <button className='btn-green'>LEARN ABOUT FOOD AND HEALTH</button>
                            </div>
                        </Col>

                        <Image imgClass="img-fluid" colMdSize={6} class="" alt="" image={bannerBlockImg} />
                    </Row>
                </Container>
                :
                <Container>
                    <Row>
                        <Image imgClass="img-fluid" colMdSize={6} class="" alt="" image={bannerBlockImg} />
                        <Col md={6}>
                            <h6>For your Daily Workout</h6>
                            <h2>High Protein Diet</h2>
                            <p>Daily exercise is the only way to stay healthier. Invest and Take care of your
                                body before you faced to to it after getting a decease. Prevention is always
                                better than cure. High Protein Diet is very much needed for your muscle strength
                                and over-all health. Checkout options to meet your daily protein requirement
                                through your food.</p>
                            <div className='bannerBlock-btngroup'>
                                <button className='btn-red'>PLAN YOUR DIET</button>
                                <button className='btn-green'>LEARN MORE ABOUT PROTEIN NEED</button>
                            </div>
                        </Col>

                    </Row>
                </Container>
            }
        </div>
    );
}

export default BannerBlock;