import { Container, Row } from "react-bootstrap";
import Footer from "../../common/components/Footer/Footer";
import Header from "../../common/components/Header/Header";
import planAdiet from "../../assets/images/planAdiet-img.png";
import activityImg from "../../assets/images/activityLevel-img.png";
import backBtn from "../../assets/icons/back-btn.svg";
import "./PlanADiet.scss";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import Image from "../../common/components/Image/Image";
import Form from 'react-bootstrap/Form';
import { TbAlertTriangleFilled } from "react-icons/tb";
import RangeSlider from "../../common/components/RangeSlider/RangeSlider";
import DietPlanOption from "../../common/components/DietPlanOption/DietPlanOption";
import Breadcrumbs from "../../common/components/Breadcrumbs/Breadcrumbs";
import { showNotification } from "../../common/components/Toaster/Toast";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { getLifStyleList } from "../../redux/pages/planADietSlice";

const PlanADiet = () => {
    const [activeDiet, setActiveDiet] = useState("");
    const [activeGender, setActiveGender] = useState("");
    const [page, setPage] = useState(1);
    const [bmi, setBmi] = useState<number>(0);
    const [lifestyleOption, setLifeStyleOption] = useState<String>('');
    const [showOption, setShowOption] = useState<boolean>(false);
    const [weight, setWeight] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    const dietList = [
        {
            name: "I Need a Weight Loss",
            showFor: 'all',
        },
        {

            name: "Need increase in weight",
            showFor: 'all',
        },
        {
            name: "Need to Control my Diabetic",
            showFor: 'all',
        },
        {
            name: "Need Special Diet for my Daily workout",
            showFor: 'all',
        },
        {
            name: "Need Diet for Fatty Liver Condition",
            showFor: 'all',
        },
        {
            name: "Need Pregnancy Diet",
            showFor: 'female',
        },
        {
            name: "I have a disease, need suitable diet",
            showFor: 'all',
        },
    ];

    const genderList = [
        {
            name: "Male",
            value: "male",
        },

        {
            name: "Female",
            value: "female",
        },
    ];

    const dietOption = [
        {
            title: 'To maintain',
            calories: '1805',
            description: 'Estimated weight after one Month Subscription Period',
            weight: '93.5',
        },
        {
            title: 'To lose',
            calories: '1444',
            description: 'Estimated weight after one Month Subscription Period',
            weight: '92.5',
        },
        {
            title: 'To lose weight',
            calories: '1083',
            description: 'Estimated weight after one Month Subscription Period',
            weight: '91.5',
        }
    ];

    const handleActiveGender = (selected: any) => {
        setActiveGender(selected);
    };

    const handleActiveDiet = (selected: any) => {
        setActiveDiet(selected);
        if (activeGender)
            updatePage();
    };

    const handleActiveLifeStyle = (selected: any) => {
        setLifeStyleOption(selected);
        setShowOption(true);
    }

    const calculateBmi = () => {
        
        if(height !== 0 && weight !== 0 && height > weight){

            const heightInM = height / 100;
            const bmi = weight / (heightInM * heightInM);

            setBmi(Math.round((bmi + Number.EPSILON) * 100) / 100);
        }
        else{
            showNotification({
                message: 'Please enter a valid height and weight!',
                theme: 'light',
                type: 'warn'
            });
        }
    }

    const updatePage = () => {
        setPage(page + 1);

        if(page === 2 && lifestyleOption !== ''){
            setShowOption(true);
        }
    }

    const backClick = () => {
        setPage(page - 1);
        setShowOption(false);
    }

    // Redux
    const dispatch = useDispatch<any>();
    const lifeStyleList = useAppSelector((state) => state.planADiet.lifeStyleList);

    useEffect(() => {
        dispatch(getLifStyleList());
    }, []);

    return (
        <Fragment>
            <Header />
            <div className="planAdiet-wrapper">

                <Breadcrumbs />

                {/* <Container fluid className="breadcrumb-wrapper">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Plan A Diet</Breadcrumb.Item>
                    </Breadcrumb>
                </Container> */}

                <div className="planAdiet-content-wrapper">
                    <Container>
                        {page == 1 ?
                            <Row>
                                <Col className="planAdiet-content" md={7}>
                                    <h5>
                                        We help you find best diet plan for you. Let
                                        us know about you?
                                    </h5>
                                    <h2>Plan A Diet</h2>
                                    <p>
                                        Let us attend a questionnaire, so our AI
                                        system will suggest you the best option for
                                        you.
                                    </p>
                                    <hr />

                                    <ul>
                                        <li className="gender-li">
                                            <div className="slNo">1</div>
                                            <h6>Gender</h6>
                                            {genderList.map((ele, idx) => (
                                                <button type="button"
                                                    onClick={() =>
                                                        handleActiveGender(
                                                            ele.value
                                                        )
                                                    }
                                                    className={
                                                        activeGender === ele.value
                                                            ? "btn-light active"
                                                            : "btn-light"
                                                    }
                                                    value={ele.value}
                                                    key={idx}
                                                >
                                                    {ele.name}
                                                </button>
                                            ))}
                                        </li>
                                        <li className="needFordiet-li">
                                            <div className="needFordiet">
                                                <div className="slNo">2</div>
                                                <h6>
                                                    Do you know your need for Diet?{" "}
                                                </h6>
                                            </div>
                                            <div className="needFordiet-btnGroup">
                                                {dietList.map((ele, idx) => (
                                                    ele.showFor === activeGender || ele.showFor === 'all' ?
                                                        <button type="button"
                                                            onClick={() =>
                                                                handleActiveDiet(
                                                                    ele.name
                                                                )
                                                            }
                                                            className={
                                                                activeDiet === ele.name
                                                                    ? "btn-light active"
                                                                    : "btn-light"
                                                            }
                                                            key={idx}
                                                        >
                                                            {ele.name}
                                                        </button>
                                                    : ''
                                                ))}
                                            </div>
                                        </li>
                                    </ul>
                                </Col>

                                <Image colMdSize={5} class="planAdiet-img" alt="" image={planAdiet} />
                            </Row>

                        : page === 2 ?

                            <Row>

                                <Col className="planAdiet-content" md={7}>
                                    <span className="back-button-wraper page-2" onClick={backClick}>
                                        <img src={backBtn} className="img-fluid" alt="back-btn" />
                                    </span>
                                    <ul>
                                        <li className="calculateBmi-li">
                                            <div className="d-flex">
                                                <div className="slNo">3</div>
                                                <h6>Let us calculate you BMI</h6>
                                            </div>
                                            <div className="heightDflex">
                                                <div>
                                                    <Form.Text id="passwordHelpBlock" muted>
                                                        Your current Weight in (KG)
                                                    </Form.Text>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Weight"
                                                        value={weight === 0 ? '' : weight}
                                                        onChange={(e: any) => setWeight(Number(e.target.value))}
                                                    />
                                                </div>

                                                <div>
                                                    <Form.Text id="passwordHelpBlock" muted>
                                                        Your Height in(cm)
                                                    </Form.Text>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Height"
                                                        value={height === 0 ? '' : height}
                                                        onChange={(e: any) => setHeight(Number(e.target.value))}
                                                    />
                                                </div>
                                                <Button type="button" className="calBmi-btn" onClick={calculateBmi}>Calculate BMI</Button>
                                            </div>
                                            <hr />

                                        </li>

                                        {bmi === 0 ?
                                            <>
                                                <li className="calcBmi-slider">
                                                    <div>
                                                        <h4>BMI (Body Mass Index)</h4>
                                                        <p>-</p>
                                                        <RangeSlider value="" tracker={false} />
                                                    </div>
                                                    <div>
                                                        <a href="#">What is BMI. Read Here</a>
                                                    </div>
                                                </li>

                                                <div className="btn-disabled">
                                                    <Button type="button">PROCEED</Button>
                                                </div>

                                                <div className="calcDflex">
                                                    <h6><TbAlertTriangleFilled />Calculate BMI to Plan your Diet</h6>
                                                    <a href="#">Exit Diet Planner, show predefined diet plans for weight loss</a>
                                                </div>
                                            </>
                                        :
                                            <>
                                                <li className="calcBmi-slider">
                                                    <div>
                                                        <h4>BMI (Body Mass Index)</h4>
                                                        <p>{bmi}</p>
                                                        <RangeSlider value={bmi} tracker={true} />
                                                    </div>
                                                    <div>
                                                        <a href="#">What is BMI. Read Here</a>
                                                    </div>
                                                </li>

                                                <div className="calcBmi-para">
                                                    {bmi < 18.5 ?
                                                        <>
                                                            <p>Your BMI indicates that you are <span className="weight-range">Under weight.</span> Make effort
                                                                to change your BMI in the range 18.5 to 24.9<br />
                                                                We guide you to find best diet plans. There is also option for
                                                                consult Dietian [Optional Service]</p>
                                                            <p>Continue, let us find what we can do.</p>
                                                        </>
                                                    : bmi >= 18.5 && bmi < 25 ?
                                                        <>
                                                            <p>Your BMI indicates that you are <span className="weight-range">Normal weight.</span></p>
                                                            <p>Continue, let us find what we can do.</p>
                                                        </>
                                                    : bmi >= 25 && bmi < 30 ?
                                                        <>
                                                            <p>Your BMI indicates that you are <span className="weight-range">Over weight.</span> Make effort
                                                                to change your BMI in the range 18.5 to 24.9<br />
                                                                We guide you to find best diet plans. There is also option for
                                                                consult Dietian [Optional Service]</p>
                                                            <p>Continue, let us find what we can do.</p>
                                                        </>
                                                    :
                                                        <>
                                                            <p>Your BMI indicates that you are <span className="weight-range">Obesity.</span> Make effort
                                                                to change your BMI in the range 18.5 to 24.9<br />
                                                                We guide you to find best diet plans. There is also option for
                                                                consult Dietian [Optional Service]</p>
                                                            <p>Continue, let us find what we can do.</p>
                                                        </>
                                                    }
                                                </div>

                                                <div className="proceed-btn">
                                                    <Button onClick={updatePage} type="button">PROCEED</Button>
                                                </div>
                                            </>
                                        }

                                    </ul>
                                </Col>

                                <Image colMdSize={5} class="planAdiet-img" alt="" image={planAdiet} />
                            </Row>

                        : page === 3 ?

                            <Row>
                                <Col className="planAdiet-content" md={7}>
                                    <span className="back-button-wraper" onClick={backClick}>
                                        <img src={backBtn} className="img-fluid" alt="back-btn" />
                                    </span>
                                    <ul className="activityLevel">
                                        <li className="activityLevel-li">
                                            <div className="d-flex">
                                                <div className="slNo">4</div>
                                                <h6>Can you select your activity level (or planned) for next
                                                    month subscription period?</h6>
                                            </div>
                                        </li>
                                        {lifeStyleList.map((ele: any, idx: any) => (
                                            <Row className="activityLevel-row" key={idx}>
                                                <Col md={4}>
                                                    <button type="button"
                                                        onClick={() =>
                                                            handleActiveLifeStyle(
                                                                ele.lifestyle
                                                            )
                                                        }
                                                        className={
                                                            lifestyleOption === ele.lifestyle
                                                                ? "btn-red"
                                                                : "btn-light"
                                                        }
                                                        value={ele.lifestyle}
                                                        key={idx}
                                                    >
                                                        {ele.lifestyle}
                                                    </button>
                                                </Col>

                                                <Col md={8}>
                                                    <p>{ele.description}</p>
                                                </Col>
                                            </Row>
                                        ))}

                                    </ul>
                                </Col>

                                <Image colMdSize={5} class="planAdiet-img" alt="image" image={activityImg} />
                            </Row>

                        : ''}
                    </Container>
                </div>
            </div>

            {showOption === false ?
                <Footer />
                : ''}

            <div className={`bottom-popup ${showOption ? 'open' : ''}`}>
                <div className="popup-content">
                    <div className="planDietOptions-wrapper">
                        {lifestyleOption !== '' ?
                            dietOption.map((option, idx) => <DietPlanOption key={idx} option={option} />)
                        :
                        ''}
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default PlanADiet;
