import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";

export interface LoginState {
    errors: any;
    isLoading: boolean;
    isAuthenticated: boolean;
    accessToken: any;
}

const initialState: LoginState = {
    errors: null,
    isLoading: false,
    isAuthenticated: false,
    accessToken: null
}

const LoginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload
        },
        setIsAuthenticated: (state, { payload }: PayloadAction<any>) => {
            state.isAuthenticated = payload
        },
        setAccessToken: (state, { payload }: PayloadAction<any>) => {
            state.accessToken = payload
        },
    }
});

export const login = (body: any, setLoginError: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try{
        let URL = '/login';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: body }, token: false});
        if(response.data) {
            dispatch(setLoading(false));
            if(response.data.status && response.data.status === 'success') {
                sessionStorage.setItem('token', response.data.token);
                dispatch(setIsAuthenticated(true));
                dispatch(setAccessToken(response.data.token));
            }
        }
    }
    catch (error: any) {
        dispatch(setLoading(false));
        setLoginError(error?.response.data?.message);
    }
}

export const {
    setErrors,
    setLoading,
    setIsAuthenticated,
    setAccessToken
} = LoginSlice.actions;

export default LoginSlice.reducer;