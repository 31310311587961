import './Breadcrumbs.scss';
import { Container, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";


const Breadcrumbs = () => {
    return (
        <Container fluid className="breadcrumb-wrapper">
            <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Plan A Diet</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
    );
}

export default Breadcrumbs;