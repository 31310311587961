import './Header.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import trayIcon from '../../../assets/icons/tray-icon.svg';
import { Link } from 'react-router-dom';

const Header = () => {
    return (

        <Navbar collapseOnSelect expand="lg" className="nav-bg fixed-top">
            <Container fluid>
                <Link className="navbar-brand" to="/">eaty<span>healthy</span></Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Link className="diet-p nav-link" to="/diet-plans">Diet Plans</Link>
                        <Link className="nav-link" to="/meal-plans">Meal Plans</Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link className='tray-p' href="#tray">My Tray <span>2</span> <img src={trayIcon} alt="" /></Nav.Link>
                        <Nav.Link href="#login">Login</Nav.Link>
                        <Nav.Link className='nav-icon' href="#icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40.998" height="40.998" viewBox="0 0 40.998 40.998">
                                <g id="square" transform="translate(0.5 0.5)">
                                    <g id="Group_92" data-name="Group 92">
                                        <g id="Group_88" data-name="Group 88">
                                            <path id="Path_24" data-name="Path 24" d="M35.832,40H4.166A4.171,4.171,0,0,1,0,35.832V4.166A4.171,4.171,0,0,1,4.166,0H35.832A4.171,4.171,0,0,1,40,4.166V35.832A4.171,4.171,0,0,1,35.832,40ZM4.166,1.667a2.5,2.5,0,0,0-2.5,2.5V35.832a2.5,2.5,0,0,0,2.5,2.5H35.832a2.5,2.5,0,0,0,2.5-2.5V4.166a2.5,2.5,0,0,0-2.5-2.5Z" stroke="#000" strokeWidth="1" />
                                        </g>
                                        <g id="Group_89" data-name="Group 89" transform="translate(11.666 11.666)">
                                            <path id="Path_25" data-name="Path 25" d="M22.833,8.667h-15A.833.833,0,1,1,7.833,7h15a.833.833,0,0,1,0,1.667Z" transform="translate(-7 -7)" stroke="#000" strokeWidth="1" />
                                        </g>
                                        <g id="Group_90" data-name="Group 90" transform="translate(11.666 19.166)">
                                            <path id="Path_26" data-name="Path 26" d="M22.833,13.167h-15a.833.833,0,1,1,0-1.667h15a.833.833,0,0,1,0,1.667Z" transform="translate(-7 -11.5)" stroke="#000" strokeWidth="1" />
                                        </g>
                                        <g id="Group_91" data-name="Group 91" transform="translate(11.666 26.665)">
                                            <path id="Path_27" data-name="Path 27" d="M22.833,17.667h-15a.833.833,0,1,1,0-1.667h15a.833.833,0,0,1,0,1.667Z" transform="translate(-7 -16)" stroke="#000" strokeWidth="1" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>




    );
}

export default Header;